@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* 
  1) Use a rounder font like Poppins 
     (adjust weights as needed: 400, 500, 600...)
*/

/* Reset margin & padding, apply rounder font */
html, body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background: #fff;
  color: #000;
  scroll-behavior: smooth;
}

/* Particles.js container behind the main content */
#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; 
}

/* 
  ─────────────────────────────────────────────────────────
   Hero Section 
  ─────────────────────────────────────────────────────────
*/
.hero-section {
  position: relative;
  z-index: 1; 
  min-height: 80vh; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0 0 16px 16px;
}

.hero-title {
  font-family: 'Avenir Next';
  font-size: 2.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #205EA3;
}

.hero-subtitle {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.hero-description {
  font-size: 1.1rem;
  max-width: 700px;
  line-height: 1.6;
}

/* 
  ─────────────────────────────────────────────────────────
   Services Section
  ─────────────────────────────────────────────────────────
*/
.services-section {
  position: relative;
  z-index: 1; 
  padding: 3rem 2rem;
  background: #f2f7fa;
  text-align: center;
  border-radius: 16px;
  margin: 2rem auto;
  max-width: 1200px;
}

.services-section h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #205EA3;
  font-weight: 600; 
}

.services-wrapper {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 2rem;
  gap: 2rem;
  justify-content: center;
}

.service-card {
  background: #fff;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  border-radius: 12px;
  max-width: 300px;
  padding: 1.5rem;
  text-align: center; /* Center header text */
  transition: box-shadow 0.2s ease;
  cursor: pointer;
}

.service-card:hover {
  box-shadow: 0 4px 12px rgba(0,0,0,0.12);
}

.service-card h3 {
  margin-top: 0;
  color: #205EA3;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  text-align: center;
}

/* Toggle Card Styles (no flip animation) */
.service-content {
  position: relative;
  width: 100%;
  height: 250px; /* adjust height as needed */
}

/* Position both icon and description absolutely within the container */
.service-icon,
.service-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
}

/* Default: show icon, hide description */
.service-icon {
  /* Center the icon and make it smaller */
  top: 50%;
  left: 50%;
  width: 60%;  /* Adjust as needed */
  height: auto;
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 2;
  filter: brightness(0) saturate(100%) invert(29%) sepia(99%) saturate(550%) hue-rotate(190deg) brightness(90%) contrast(90%);
}

.service-description {
  opacity: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;  /* Remove extra padding */
  margin: 0;   /* Ensure no unexpected margins */
}

/* On hover or when the container has the 'active' class, swap icon and text */
.service-content:hover .service-icon,
.service-content.active .service-icon {
  opacity: 0;
}

.service-content:hover .service-description,
.service-content.active .service-description {
  opacity: 1;
}


/* 
  ─────────────────────────────────────────────────────────
   Trusted By Section
  ─────────────────────────────────────────────────────────
*/
.trusted-by-section {
  position: relative;
  z-index: 1; 
  padding: 3rem 2rem;
  text-align: center;
  background: #fff;
  margin: 2rem auto; 
  max-width: 1200px;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.trusted-by-section h2 {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  color: #205EA3;
  font-weight: 600;
}

/* 
  ─────────────────────────────────────────────────────────
   Responsive Tweaks
  ─────────────────────────────────────────────────────────
*/
@media (max-width: 768px) {
  .hero-title {
    font-size: 2rem;
  }
  .hero-subtitle {
    font-size: 1.4rem;
  }
  .services-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .service-card {
    max-width: 90%;
  }
  .trusted-by-section h2 {
    font-size: 1.6rem;
  }
}

:root {
  --off-white: #ffffff;
  --light-gray: #9ca6a2;
}

section {
  background: var(--off-white);
}

/* Container viewport: 840px wide */
.brandsCarousel {
  width: 70vw;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  background: #fff;
}

/* The track is 1320px so all 6 big-margin logos fit */
.carouselTrack {
  width: 100vw;
  display: flex;
  animation: infiniteLoop 10s linear infinite;
}

/* If you want the first brand flush at left, 
   offset by -60px initially. Adjust the final to -720px. */
@keyframes infiniteLoop {
  0% {
    transform: translate3d(0, 0, 0);
    /* or translate3d(-60px, 0, 0) if you want no initial blank space */
  }
  100% {
    transform: translate3d(-660px, 0, 0);
    /* or -720px if you started at -60px */
  }
}

/* 100px wide, 60px margin each side => 220px total per logo */
.brandLogo {
  width: 100px;
  height: 50px;
  margin: 0 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Force all SVG paths to be gray */
.brandLogo svg * {
  fill: var(--light-gray) !important;
}

/* Keep aspect ratio on logos */
.brandLogo svg {
  width: 100px;
  height: auto;
}

/* mission.css */

/* Layout container */
.mission-container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Inter', sans-serif; /* or your preferred font */
    color: #333;
  }
  
  /* Hero / Intro */
  .mission-hero {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .mission-hero h1 {
    font-size: 2.2rem;
    color: #205EA3;
    margin-bottom: 1rem;
  }
  
  .mission-hero p {
    font-size: 1.1rem;
    line-height: 1.6;
    max-width: 700px;
    margin: 0 auto;
  }
  
  /* Main content section */
  .mission-content {
    margin-bottom: 3rem;
  }
  
  .mission-text-block {
    margin-bottom: 2rem;
  }
  
  .mission-text-block h2 {
    font-size: 1.5rem;
    color: #205EA3;
    margin-bottom: 0.75rem;
  }
  
  .mission-text-block p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  
  /* Paper snippet styling */
  .snippet-block {
    background: #fafafa;
    border-left: 4px solid #205EA3;
    padding: 1rem 1.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  
  .paper-quote {
    margin-top: 1rem;
    font-style: italic;
    color: #555;
  }
  
  /* Call to Action */
  .mission-cta {
    text-align: center;
    background: #f7f7f7;
    padding: 2rem;
    border-radius: 4px;
  }
  
  .mission-cta h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #205EA3;
  }
  
  .mission-cta p {
    font-size: 1rem;
    line-height: 1.5;
    max-width: 600px;
    margin: 0.5rem auto 2rem;
    color: #555;
  }
  
  /* CTA Button */
  .cta-button {
    display: inline-block;
    background: #205EA3;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    font-size: 1rem;
    padding: 0.8rem 1.4rem;
    border-radius: 4px;
    transition: background 0.3s ease;
  }
  
  .cta-button:hover {
    background: #003366;
    transform: scale(1.05);
  }

  .paper-button {
    display: inline-block;
    background-color: #205EA3; /* Your brand blue */
    color: white;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.75rem 1.5rem;
    border-radius: 8px; /* Rounded edges */
    transition: background 0.3s ease-in-out, transform 0.2s ease-in-out;
    text-align: center;
  }
  
  /* Hover effect */
  .paper-button:hover {
    background-color: #003366; /* Darker blue on hover */
    transform: scale(1.05); /* Slight pop-out effect */
  }
  
  /* Basic Responsive Tweaks */
  @media (max-width: 768px) {
    .mission-hero h1 {
      font-size: 1.8rem;
    }
    .mission-hero p,
    .mission-text-block p,
    .mission-cta p {
      font-size: 0.95rem;
    }
    .paper-button {
      font-size: 0.9rem;
      padding: 0.6rem 1.2rem;
    }
  }
  
/* 
   1) Use a rounder font like Poppins.
      Adjust weights as needed: 400, 500, 600...
*/

/* Container */
.benefits-container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Poppins', sans-serif; /* Rounder than Inter */
  color: #333;
  padding-top: 5rem;
}

/* Intro Section */
.benefits-intro {
  text-align: center;
  margin-bottom: 3rem;
}

.benefits-intro h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #205EA3;
  font-weight: 600;
}

.benefits-intro p {
  font-size: 1rem;
  line-height: 1.6;
  max-width: 700px;
  margin: 0.5rem auto;
}

/* Timeline Section */
.timeline-section {
  margin-bottom: 3rem;
}

.timeline-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}

/* Each timeline (Traditional / Cornucopia) */
.timeline {
  background: #f9f9f9;
  border-radius: 12px; /* more rounded corners */
  padding: 1.5rem;
  box-shadow: 0 2px 6px rgba(0,0,0,0.05);
}

.timeline h2 {
  margin-top: 0;
  font-size: 1.4rem;
  color: #205EA3;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 600;
}

/* Timeline Steps as a horizontal list */
.timeline-steps {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem 0;
}

/* Each step */
.timeline-step {
  min-width: 140px;
  padding: 1rem;
  background: #fff;
  border-radius: 8px; /* rounder corners */
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  text-align: center;
  flex-shrink: 0;
  transition: box-shadow 0.2s ease;
}

.timeline-step:hover {
  box-shadow: 0 2px 5px rgba(0,0,0,0.15);
}

.timeline-step span {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
}

/* Distinguish "earlier steps" for the Cornucopia timeline */
.earlier-steps {
  background: #e7f0fb;
}

/* A special "skip-label" to indicate skipping steps */
.skip-label {
  background: #ffeeb3;
  font-size: 0.8rem;
}

/* The highlight step (In Vivo) in the Cornucopia timeline */
.highlight {
  background: #205EA3;
  color: #fff;
}

/* Borders on the left side for timeline distinction */
.traditional {
  border-left: 6px solid #fca311;
  border-radius: 12px; /* match the container radius */
}

.cornucopia {
  border-left: 6px solid #205EA3;
  border-radius: 12px; /* match the container radius */
}

/* Benefits Highlights Section */
.benefits-highlights {
  margin-top: 3rem;
}

.benefits-highlights h2 {
  text-align: center;
  font-size: 1.6rem;
  color: #205EA3;
  margin-bottom: 2rem;
  font-weight: 600;
}

.benefit-point {
  margin-bottom: 2rem;
}

.benefit-point h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.benefit-point p {
  font-size: 1rem;
  line-height: 1.6;
}

/* Responsive */
@media (max-width: 768px) {
  .timeline-steps {
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
  }

  .timeline-step {
    min-width: unset;
    width: 100%;
    text-align: center;
  }
  
  .timeline h2 {
    font-size: 1.2rem;
  }
  .timeline-step span {
    font-size: 0.8rem;
  }
  .benefit-point h3 {
    font-size: 1.1rem;
  }
}

/* contact_us.css */

/* 
  Use a rounder font like Poppins 
  (adjust weights as needed: 400, 500, 600...)
*/

/* Container */
.contact-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Poppins', sans-serif; /* Switched to Poppins */
  color: #333;
  padding-top: 5rem;
}

/* Intro Section */
.contact-intro {
  text-align: center;
  margin-bottom: 2rem;
}

.contact-intro h2 {
  font-size: 2rem;
  color: #205EA3; /* brand color if desired */
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.contact-intro p {
  font-size: 1rem;
  line-height: 1.6;
  margin: 0 auto;
  max-width: 600px;
}

/* 
  Main Content: 
  2-column layout on larger screens,
  but wraps nicely on mobile 
*/
.contact-content {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 2rem;
  gap: 2rem;
}

/* Left column: contact info card */
.contact-info {
  flex: 1 1 250px;
  background: #f9f9f9;
  padding: 1.5rem;
  border-radius: 12px; /* larger radius for a rounder card */
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.contact-info h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  color: #205EA3;
  font-weight: 600;
}

.contact-info p {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
}

.contact-info strong {
  color: #000;
}

/* Right column: form card */
.contact-form {
  flex: 2 1 400px;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 1.5rem;
  border-radius: 12px; /* bigger radius here too */
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.contact-form h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  color: #205EA3;
  font-weight: 600;
}

/* Form Labels */
.contact-form label {
  margin: 0.8rem 0 0.2rem;
  font-weight: 500;
  font-size: 0.95rem;
}

/* Inputs and Textareas */
.contact-form input,
.contact-form textarea {
  padding: 0.8rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 8px; /* rounder corners on inputs */
  font-size: 0.95rem;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-color: #205EA3;
  box-shadow: 0 0 0 2px rgba(32,94,163,0.1);
}

/* Submit Button */
.btn-submit {
  background: #205EA3; /* brand color */
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  border: none;
  padding: 0.8rem 1.2rem;
  border-radius: 8px; /* slightly rounder button */
  cursor: pointer;
  margin-top: 1rem;
  transition: background 0.3s ease;
  align-self: flex-start; /* keep button to the left */
}

.btn-submit:hover {
  background: #003366;
}

/* Responsive Tweaks */
@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
  }
  
  .contact-info,
  .contact-form {
    flex: 1 1 auto;
  }
  
  .contact-info,
  .contact-form {
    margin-bottom: 1.5rem;
  }
}

/* 
  1) Use a rounder font like Poppins instead of Inter or Space Grotesk.
     Adjust weights as needed (400, 600, 700, etc.). 
*/

/* 
  2) Navbar Container 
     - Slightly rounded bottom corners
     - White background
*/
/* NAVBAR BASE STYLING */
.navbar {
    font-family: 'Poppins', sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    border-radius: 0 0 16px 16px;
    padding: 0.75rem 5%;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* LOGO */
.logo-container {
    flex-shrink: 0;
}

/* NAV LINKS (Desktop) */
.nav-links {
    display: flex;
    list-style: none;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin: 0;
    padding: 0;
}

.nav-links li a {
    display: inline-block; /* Add this line */
    text-decoration: none;
    color: #205EA3;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    transition: transform 0.2s ease, color 0.2s ease;
    position: relative; /* Needed for the pseudo-element */
}

/* Underline pseudo-element */
.nav-links li a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 5px; /* Adjust vertical position as needed */
    width: 100%;
    height: 2px; /* Underline thickness */
    background-color: #003366;
    transform: scaleX(0); /* Start hidden */
    transform-origin: bottom right;
    transition: transform 0.2s ease;
}

.nav-links li a:hover {
    transform: scale(1.22); /* Increase scale for a larger text effect */
    color: #003366;
}

.nav-links li a:hover::after {
    transform: scaleX(1); /* Expand underline to full width */
    transform-origin: bottom left;
}


/* HAMBURGER MENU */
.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    grid-gap: 5px;
    gap: 5px;
    z-index: 1100;
}

.hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: #205EA3;
    border-radius: 3px;
    transition: all 0.3s ease;
}

/* When menu is open, rotate bars into an "X" */
.hamburger.open .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.hamburger.open .bar:nth-child(2) {
    opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

/* MOBILE MENU (Dropdown Below Navbar) */
@media (max-width: 768px) {
    .hamburger {
        display: flex;
    }

    /* Initially hidden */
    .nav-links {
        position: absolute;
        top: 60px; /* Adjust based on the navbar height */
        left: 0;
        width: 100%;
        height: 0;
        background: white;
        flex-direction: column;
        grid-gap: 1rem;
        gap: 1rem;
        text-align: center;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        padding: 0;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
    }

    /* Show menu when active */
    .nav-links.active {
        height: auto; /* Adjust based on content */
        padding: 1rem 0;
    }
}

