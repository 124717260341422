/* 
  1) Use a rounder font like Poppins instead of Inter or Space Grotesk.
     Adjust weights as needed (400, 600, 700, etc.). 
*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/* 
  2) Navbar Container 
     - Slightly rounded bottom corners
     - White background
*/
/* NAVBAR BASE STYLING */
.navbar {
    font-family: 'Poppins', sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    border-radius: 0 0 16px 16px;
    padding: 0.75rem 5%;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* LOGO */
.logo-container {
    flex-shrink: 0;
}

/* NAV LINKS (Desktop) */
.nav-links {
    display: flex;
    list-style: none;
    gap: 1.5rem;
    margin: 0;
    padding: 0;
}

.nav-links li a {
    display: inline-block; /* Add this line */
    text-decoration: none;
    color: #205EA3;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    transition: transform 0.2s ease, color 0.2s ease;
    position: relative; /* Needed for the pseudo-element */
}

/* Underline pseudo-element */
.nav-links li a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 5px; /* Adjust vertical position as needed */
    width: 100%;
    height: 2px; /* Underline thickness */
    background-color: #003366;
    transform: scaleX(0); /* Start hidden */
    transform-origin: bottom right;
    transition: transform 0.2s ease;
}

.nav-links li a:hover {
    transform: scale(1.22); /* Increase scale for a larger text effect */
    color: #003366;
}

.nav-links li a:hover::after {
    transform: scaleX(1); /* Expand underline to full width */
    transform-origin: bottom left;
}


/* HAMBURGER MENU */
.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    gap: 5px;
    z-index: 1100;
}

.hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: #205EA3;
    border-radius: 3px;
    transition: all 0.3s ease;
}

/* When menu is open, rotate bars into an "X" */
.hamburger.open .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.hamburger.open .bar:nth-child(2) {
    opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

/* MOBILE MENU (Dropdown Below Navbar) */
@media (max-width: 768px) {
    .hamburger {
        display: flex;
    }

    /* Initially hidden */
    .nav-links {
        position: absolute;
        top: 60px; /* Adjust based on the navbar height */
        left: 0;
        width: 100%;
        height: 0;
        background: white;
        flex-direction: column;
        gap: 1rem;
        text-align: center;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        padding: 0;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
    }

    /* Show menu when active */
    .nav-links.active {
        height: auto; /* Adjust based on content */
        padding: 1rem 0;
    }
}
