/* 
  1) Use a rounder font like Poppins 
     (adjust weights as needed: 400, 500, 600...)
*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/* Reset margin & padding, apply rounder font */
html, body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background: #fff;
  color: #000;
  scroll-behavior: smooth;
}

/* Particles.js container behind the main content */
#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; 
}

/* 
  ─────────────────────────────────────────────────────────
   Hero Section 
  ─────────────────────────────────────────────────────────
*/
.hero-section {
  position: relative;
  z-index: 1; 
  min-height: 80vh; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0 0 16px 16px;
}

.hero-title {
  font-family: 'Avenir Next';
  font-size: 2.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #205EA3;
}

.hero-subtitle {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.hero-description {
  font-size: 1.1rem;
  max-width: 700px;
  line-height: 1.6;
}

/* 
  ─────────────────────────────────────────────────────────
   Services Section
  ─────────────────────────────────────────────────────────
*/
.services-section {
  position: relative;
  z-index: 1; 
  padding: 3rem 2rem;
  background: #f2f7fa;
  text-align: center;
  border-radius: 16px;
  margin: 2rem auto;
  max-width: 1200px;
}

.services-section h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #205EA3;
  font-weight: 600; 
}

.services-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.service-card {
  background: #fff;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  border-radius: 12px;
  max-width: 300px;
  padding: 1.5rem;
  text-align: center; /* Center header text */
  transition: box-shadow 0.2s ease;
  cursor: pointer;
}

.service-card:hover {
  box-shadow: 0 4px 12px rgba(0,0,0,0.12);
}

.service-card h3 {
  margin-top: 0;
  color: #205EA3;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  text-align: center;
}

/* Toggle Card Styles (no flip animation) */
.service-content {
  position: relative;
  width: 100%;
  height: 250px; /* adjust height as needed */
}

/* Position both icon and description absolutely within the container */
.service-icon,
.service-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
}

/* Default: show icon, hide description */
.service-icon {
  /* Center the icon and make it smaller */
  top: 50%;
  left: 50%;
  width: 60%;  /* Adjust as needed */
  height: auto;
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 2;
  filter: brightness(0) saturate(100%) invert(29%) sepia(99%) saturate(550%) hue-rotate(190deg) brightness(90%) contrast(90%);
}

.service-description {
  opacity: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;  /* Remove extra padding */
  margin: 0;   /* Ensure no unexpected margins */
}

/* On hover or when the container has the 'active' class, swap icon and text */
.service-content:hover .service-icon,
.service-content.active .service-icon {
  opacity: 0;
}

.service-content:hover .service-description,
.service-content.active .service-description {
  opacity: 1;
}


/* 
  ─────────────────────────────────────────────────────────
   Trusted By Section
  ─────────────────────────────────────────────────────────
*/
.trusted-by-section {
  position: relative;
  z-index: 1; 
  padding: 3rem 2rem;
  text-align: center;
  background: #fff;
  margin: 2rem auto; 
  max-width: 1200px;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.trusted-by-section h2 {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  color: #205EA3;
  font-weight: 600;
}

/* 
  ─────────────────────────────────────────────────────────
   Responsive Tweaks
  ─────────────────────────────────────────────────────────
*/
@media (max-width: 768px) {
  .hero-title {
    font-size: 2rem;
  }
  .hero-subtitle {
    font-size: 1.4rem;
  }
  .services-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .service-card {
    max-width: 90%;
  }
  .trusted-by-section h2 {
    font-size: 1.6rem;
  }
}
