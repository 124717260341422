/* 
   1) Use a rounder font like Poppins.
      Adjust weights as needed: 400, 500, 600...
*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/* Container */
.benefits-container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Poppins', sans-serif; /* Rounder than Inter */
  color: #333;
  padding-top: 5rem;
}

/* Intro Section */
.benefits-intro {
  text-align: center;
  margin-bottom: 3rem;
}

.benefits-intro h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #205EA3;
  font-weight: 600;
}

.benefits-intro p {
  font-size: 1rem;
  line-height: 1.6;
  max-width: 700px;
  margin: 0.5rem auto;
}

/* Timeline Section */
.timeline-section {
  margin-bottom: 3rem;
}

.timeline-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Each timeline (Traditional / Cornucopia) */
.timeline {
  background: #f9f9f9;
  border-radius: 12px; /* more rounded corners */
  padding: 1.5rem;
  box-shadow: 0 2px 6px rgba(0,0,0,0.05);
}

.timeline h2 {
  margin-top: 0;
  font-size: 1.4rem;
  color: #205EA3;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 600;
}

/* Timeline Steps as a horizontal list */
.timeline-steps {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  gap: 1rem;
  padding: 1rem 0;
}

/* Each step */
.timeline-step {
  min-width: 140px;
  padding: 1rem;
  background: #fff;
  border-radius: 8px; /* rounder corners */
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  text-align: center;
  flex-shrink: 0;
  transition: box-shadow 0.2s ease;
}

.timeline-step:hover {
  box-shadow: 0 2px 5px rgba(0,0,0,0.15);
}

.timeline-step span {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
}

/* Distinguish "earlier steps" for the Cornucopia timeline */
.earlier-steps {
  background: #e7f0fb;
}

/* A special "skip-label" to indicate skipping steps */
.skip-label {
  background: #ffeeb3;
  font-size: 0.8rem;
}

/* The highlight step (In Vivo) in the Cornucopia timeline */
.highlight {
  background: #205EA3;
  color: #fff;
}

/* Borders on the left side for timeline distinction */
.traditional {
  border-left: 6px solid #fca311;
  border-radius: 12px; /* match the container radius */
}

.cornucopia {
  border-left: 6px solid #205EA3;
  border-radius: 12px; /* match the container radius */
}

/* Benefits Highlights Section */
.benefits-highlights {
  margin-top: 3rem;
}

.benefits-highlights h2 {
  text-align: center;
  font-size: 1.6rem;
  color: #205EA3;
  margin-bottom: 2rem;
  font-weight: 600;
}

.benefit-point {
  margin-bottom: 2rem;
}

.benefit-point h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.benefit-point p {
  font-size: 1rem;
  line-height: 1.6;
}

/* Responsive */
@media (max-width: 768px) {
  .timeline-steps {
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
  }

  .timeline-step {
    min-width: unset;
    width: 100%;
    text-align: center;
  }
  
  .timeline h2 {
    font-size: 1.2rem;
  }
  .timeline-step span {
    font-size: 0.8rem;
  }
  .benefit-point h3 {
    font-size: 1.1rem;
  }
}
